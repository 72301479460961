/* You can add global styles to this file, and also import other style files */

$gutter: 1rem; //for primeflex grid system
@import "assets/layout/styles/layout/layout.scss";

/* PrimeNG */
@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";

body {
  & .infinit-scroll-dialog {
    overflow-y: overlay;
    overflow-x: hidden;
    height: 65vh;
    padding: 10px 35px 10px 10px;
  }
  & .float-right{
    float: right;
  }
}
