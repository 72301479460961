.card {
  background: var(--surface-card);
  border: 1px solid var(--surface-border);
  padding: 2rem;
  margin-bottom: 2rem;
  box-shadow: var(--card-shadow);
  border-radius: $borderRadius;

  &:last-child {
    margin-bottom: 0;
  }
}

.p-toast {
  &.p-toast-top-right,
  &.p-toast-top-left,
  &.p-toast-top-center {
    top: 100px;
  }
}

.imagem {
  border-radius: $borderRadius;
  img {
    border-radius: $borderRadius;
  }
}

.imagem-placeholder {
  height: 200px;
  width: 200px;
  background: var(--surface-b);
  border: 1px solid var(--surface-border);
  border-radius: $borderRadius;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
  .icon {
    font-size: 20px;
    color: #c4c4c4;
  }
}
